import React from 'react';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

export default function Index() {
  return (
    <div>
      <Typography variant='h5'>
        Sorry, we couldn't find that page.
      </Typography>
      <Box my={4}>
        <Divider />
      </Box>
    </div >
  );
};
